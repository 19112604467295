
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class RedirectView extends Vue {
  /**
   * Lifecycle hooks
   */
  private created() {
    this.$router.push({ name: this.$route.query.to as string });
  }

  /**
   * Render
   */
  private render() {
    //
  }
}
